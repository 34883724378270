//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    icon: {
      type: Object,
      default: () => {}
    },
    text: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    return {}
  }
})
